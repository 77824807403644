import React from 'react';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
const {
  Option
} = Select;
interface LanguageData {
  key: string;
  label: string;
  shortLabel: string;
}
const availableLanguages: LanguageData[] = [
// tslint:disable-next-line:no-duplicate-string
{
  key: 'en',
  label: 'English',
  shortLabel: 'EN'
}, {
  key: 'en-US',
  label: 'English (USA)',
  shortLabel: 'EN'
}, {
  key: 'en-GB',
  label: 'English (UK)',
  shortLabel: 'EN'
}, {
  key: 'pt-BR',
  label: 'Portuguese (BR)',
  shortLabel: 'PT'
}, {
  key: 'es',
  label: 'Spanish',
  shortLabel: 'ES'
}, {
  key: 'de',
  label: 'German',
  shortLabel: 'DE'
}, {
  key: 'hu',
  label: 'Hungarian',
  shortLabel: 'HU'
}, {
  key: 'bg',
  label: 'Bulgarian',
  shortLabel: 'BG'
}, {
  key: 'ro',
  label: 'Romanian',
  shortLabel: 'RO'
}, {
  key: 'el',
  label: 'Greek',
  shortLabel: 'EL'
}, {
  key: 'da',
  label: 'Danish',
  shortLabel: 'DA'
}, {
  key: 'nl',
  label: 'Dutch',
  shortLabel: 'NL'
}, {
  key: 'pl',
  label: 'Polish',
  shortLabel: 'PL'
}, {
  key: 'fr',
  label: 'French',
  shortLabel: 'FR'
}, {
  key: 'uk',
  label: 'Ukrainian',
  shortLabel: 'UK'
}].sort((a, b) => a.label > b.label ? 1 : -1);
interface IAvailableLanguagesDropdownProps {
  preSelectedLanguageKey: string;
  onClick: (languageKey: string) => void;
}
export const AvailableLanguagesDropdown: React.FC<IAvailableLanguagesDropdownProps> = props => {
  const getSelectedLanguageLabelByKey = (languageKey: string) => {
    const s = availableLanguages.find(lan => {
      return lan.key === languageKey;
    });
    return s?.label || 'English (USA)';
  };
  return <Select showSearch={true} defaultValue={getSelectedLanguageLabelByKey(props.preSelectedLanguageKey)} onChange={languageKey => props.onClick(languageKey)} style={{
    width: '180px'
  }} filterOption={(input, option) => {
    const optionLabel: string = (option?.label as string)?.toLowerCase() ?? '';
    const key = (option?.key as string);
    const optionKey = key.toLowerCase();
    const normalizedInput = input.toLowerCase();
    return optionLabel.includes(normalizedInput) || optionKey.includes(normalizedInput);
  }} data-sentry-element="Select" data-sentry-component="AvailableLanguagesDropdown" data-sentry-source-file="index.tsx">
          {availableLanguages.map(lan => <Option key={lan.key} value={lan.key} label={lan.label}>{lan.label}</Option>)}
      </Select>;
};